import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClientService } from '@schneideress/za-ui-controls';
import { environment } from 'src/environments/environment';
import { IRegion } from './interfaces/region.interface';
import { ISiteDetails } from 'src/app/types/site-details.interface';
import { TranslateService } from '@schneideress/za-ui-controls';

@Injectable({
  providedIn: 'root',
})
export class SiteManagementService {
  constructor(
    private httpService: HttpClientService,
    private translateService: TranslateService,
  ) {}

  getAllSites(): Observable<ISiteDetails[]> {
    return this.httpService.get(environment.apiBaseUrl + '/site/list');
  }

  getUserSites(addAll = false): Observable<ISiteDetails[]> {
    return this.httpService.get<ISiteDetails[]>(environment.apiBaseUrl + '/site/userList').pipe(
      map((sites: ISiteDetails[]) => {
        if (addAll) {
          const allSite: ISiteDetails = {
            SiteId: -1,
            SiteName: this.translateService.translate('Shared.all'),
            Rank: 0,
            SiteTypeId: 0,
            SiteSubTypeId: 0,
            OwnershipName: '',
            AddressInfo: undefined,
            SiteAttributeObj: undefined,
            Longitude: 0,
            Latitude: 0,
            ClimateZone: undefined,
          };
          return [allSite, ...sites];
        }
        return sites;
      }),
    );
  }

  getSiteDetails(siteId: number): Observable<ISiteDetails> {
    return this.httpService.get(environment.apiBaseUrl + '/site/?siteId=' + siteId);
  }

  getRoadmapCompleteSites(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/site/roadmapCompleteList');
  }

  getStateByCountry(countryId: number): Observable<IRegion[]> {
    return this.httpService.get(
      environment.apiBaseUrl + '/common/data/states?countryId=' + countryId,
    );
  }

  saveSiteDetails(siteDetailObj: object): Observable<any> {
    return this.httpService.post(environment.apiBaseUrl + '/site/', siteDetailObj);
  }

  deleteSite(siteId: number): Observable<any> {
    return this.httpService.delete(environment.apiBaseUrl + '/site/?siteId=' + siteId);
  }

  getSiteLimit(): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/common/sitelimit');
  }

  getSiteInvoiceAction(siteId: number): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/track/latestAction?siteId=' + siteId);
  }

  getRoadMapGenerationTime(siteId: number): Observable<any> {
    return this.httpService.get(environment.apiBaseUrl + '/roadmap/generatedTime/' + siteId);
  }
}
